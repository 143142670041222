import React, { useContext, useEffect, useState } from 'react';
import api from '../../api';
import useBEM from "../../useBEM";
import { StateContext } from '../../App';

import Loader from "../../Components/Loader";

import "../Student/Student.scss";
import ToggleButton from '../../Components/ToggleButton';
import InstructorSubmissions from './InstructorSubmissions';
import InstructorProgress from './InstructorProgress';
import UserCard from '../../Components/UserCard';
import MergeAccountModal from '../../Components/MergeAccountModal';

function InstructorDashboard() {
    const [isReady, setIsReady] = useState(false);

    const [option, setOption] = useState('Dashboard');

    const [submissions, setSubmissions] = useState([]);
    const [evals, setEvals] = useState([]);
    const [profile, setProfile] = useState({});

    const [state] = useContext(StateContext);
    const [block,element] = useBEM('student-dashboard');

    useEffect(() => {
        async function redirect() {
            if (state?.user?.userRoles?.find(x=>x === 'instructor')) {
                var evals = await api.GetEvalsForInstructor();
                setSubmissions(evals.Submissions);
                setEvals(evals.Evals);
                var profile = await api.getProfile();
                setProfile(profile);
                setIsReady(true);
            }
        };
        if (state.user) {
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);


    return (
        <div className={block()}>
            <h1 className={element('title')}>Instructor Dashboard</h1>

            <MergeAccountModal />

            <Loader isReady={isReady}>

                <ToggleButton options={['Dashboard', 'Submissions']} onClick={(option) => setOption(option)} selected={option} />
                
                <div className={element('layout')}>
                    <UserCard profile={profile} />

                    <div className={element('content')}>
                        { option === 'Dashboard' && <InstructorProgress evals={evals} /> }
                        { option === 'Submissions' && <InstructorSubmissions evals={submissions} /> }
                    </div>
                </div>


            </Loader>
        </div>
    );
}

export default InstructorDashboard;